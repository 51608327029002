import React from "react";
import { techStackDetails } from "../Details";

function Technologies() {
  const {
    html,
    css,
    js,
    react,
    redux,
    tailwind,
    bootstrap,
    sass,
    vscode,
    git,
    github,
    npm,
    postman,
    figma,
    mongo,
    mysql,
    typescript,
    java,
    jquery,
    angular, 
    node,
    workbench,
    apigee,
    netbeans,
    heroku,
    xml,
    kibana
  } = techStackDetails;
  return (
    <main className="container mx-auto max-width pt-10 pb-20 ">
      <section>
        <h1 className="text-2xl text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tech Stack
        </h1>
        
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img src={html} title="html" alt="" />
        <img src={css} title="CSS" alt="" />
        <img src={xml} title="XML" alt="" />
        <img src={js} title="JavaScript" alt="" />
        <img src={typescript} title="TypeScript" alt="" />
        <img src={java} title="Java" alt="" />
        <img src={node} title="Node JS" alt="" />
        <img src={jquery} title="jQuery" alt="" />
        <img src={react} title="React" alt="" />
        <img src={angular} title="Angular" alt="" />
        <img src={mongo} title="MongoDB" alt="" />
        <img src={mysql} title="MySQL" alt="" />
        <img src={tailwind} title="Tailwind CSS" alt="" />
        <img src={bootstrap} title="Bootstrap" alt="" />
        <img src={sass} title="SASS" alt="" />
      </section>
      <section>
        <h1 className="text-2xl pt-10 text-dark-heading dark:text-light-heading md:text-4xl xl:text-5xl xl:leading-tight font-bold">
          Tools
        </h1>
      </section>
      <section className="grid grid-cols-4 md:grid-cols-5 lg:grid-cols-6 items-center gap-10 pt-6">
        <img src={vscode} title="Visual Studio Code" alt="" />
        <img src={netbeans} title="NetBeans" alt="" />
        <img src={git} title="Git" alt="Git" />
        <img src={github} title="Github" alt="Github" />
        <img src={npm} title="NPM" alt="NPM" />
        <img src={apigee} title="Apigee" alt="" />
        <img src={postman} title="Postman" alt="Postman" />
        <img src={heroku} title="Heroku" alt="Heroku" />
        <img src={workbench} title="MySQL Workbench" alt="Workbench" />
        <img src={kibana} title="Kibana" alt="Kibana" />
      </section>
    </main>
  );
}

export default Technologies;
