// Enter all your detials in this file
// Logo images
import logogradient from "./assets/logo.svg";
import logo from "./assets/logo2.svg";
// Profile Image
import profile from "./assets/IMG_3153.jpg";
// Tech stack images
import html from "./assets/techstack/html.png";
import css from "./assets/techstack/css.png";
import sass from "./assets/techstack/sass.png";
import js from "./assets/techstack/js.png";
import react from "./assets/techstack/react.png";
import redux from "./assets/techstack/redux.png";
import tailwind from "./assets/techstack/tailwind.png";
import bootstrap from "./assets/techstack/bootstrap.png";
import vscode from "./assets/techstack/vscode.png";
import github from "./assets/techstack/github.png";
import git from "./assets/techstack/git.png";
import npm from "./assets/techstack/npm.png";
import postman from "./assets/techstack/postman.png";
import figma from "./assets/techstack/figma.png";
import mysql from "./assets/techstack/mysqll.png";
import mongo from "./assets/techstack/mongodbb.svg";
import typescript from "./assets/techstack/typescript.png";
import java from "./assets/techstack/java.svg"
import jquery from "./assets/techstack/jquery.svg"
import angular from "./assets/techstack/angular.png"
import node from "./assets/techstack/nodejs.svg"
import workbench from "./assets/techstack/MySQLWorkbench.png"
import apigee from "./assets/techstack/apigee.svg"
import netbeans from "./assets/techstack/netbeans.png"
import heroku from "./assets/techstack/heroku.svg"
import xml from "./assets/techstack/xml.svg"


// Porject Images
import projectImage1 from "./assets/projects/getaway.png";
import projectImage2 from "./assets/projects/side-line.png";
import projectImage3 from "./assets/projects/project3.jpg";
// import projectImage4 from "./assets/projects/project4.jpg";
// import projectImage5 from "./assets/projects/project5.jpg";
// import projectImage6 from "./assets/projects/project6.jpg";

// Logos
export const logos = {
  logogradient: logogradient,
  logo: logo,
};

// Enter your Personal Details here
export const personalDetails = {
  name: "Eduardo Ibarra",
  tagline: "Software Engineer",
  img: profile,
  about: `I am a results-driven Software Engineer with over four years of experience developing robust applications and streamlining customer interactions working with clients such as Capital One, Memorial Hermann, and Farmers Insurance. 
  My expertise includes building scalable systems, integrating and building APIs, and ensuring data security across various platforms. 
  I have a strong background in full-stack web development, specializing in JavaScript, Node.js, React, MongoDB, and more. 
  My collaborative approach within Agile & Kanban teams allows me to deliver high-impact solutions efficiently while focusing on user experience and performance optimization.
   I'm continuously learning and adapting, with a passion for solving technical challenges.`,
};

// Enter your Social Media URLs here
export const socialMediaUrl = {
  linkdein: "https://www.linkedin.com/in/ibarrasb/",
  github: "https://www.github.com/ibarrasb",
  twitter: "https://twitter.com/",
  instagram: "https://www.instagram.com/",
};

// Enter your Work Experience here
export const workDetails = [
  {
    Position: "Software Engineer",
    Company: `[24]7.ai`,
    Location: "Plano, TX",
    Type: "Full Time",
    Duration: "Oct 2021 - Present",
  },
  {
    Position: "Web Developer",
    Company: `Disaster Accountability Project`,
    Location: "Remote",
    Type: "Internship",
    Duration: "Jan 2021 - Oct 2021",
  },
  
];

// Enter your Education Details here
export const eduDetails = [
  {
    Position: "Professional Full Stack Developer Certification",
    Company: "Southern Methodist University",
    Location: "Dallas",
    Type: "Full Time",
    Duration: "",
  },
  {
    Position: "Associates in Science",
    Company: `Dallas College`,
    Location: "Dallas",
    Type: "Full Time",
    Duration: "",
  },
];

// Tech Stack and Tools
export const techStackDetails = {
  html: html,
  css: css,
  js: js,
  react: react,
  redux: redux,
  sass: sass,
  tailwind: tailwind,
  bootstrap: bootstrap,
  vscode: vscode,
  postman: postman,
  npm: npm,
  git: git,
  github: github,
  figma: figma,
  mysql: mysql,
  mongo:mongo,
  typescript: typescript,
  java: java,
  jquery: jquery,
  angular: angular,
  node: node,
  workbench: workbench,
  apigee: apigee,
  netbeans: netbeans,
  heroku: heroku,
  xml: xml,
};

// Enter your Project Details here
export const projectDetails = [
  {
    title: "Getaway Genius",
    image: projectImage1,
    description: `Vacation planning and budgeting application, enabling users to efficiently manage their travel plans and expenses.`,
    techstack: "JavaScript, Node.js, Express, MongoDB, Mongoose, React.js JWT, Google Places API, Open AI API, OpenWeather API,  Material-UI, Heroku",
    previewLink: "https://getawaygenius-app-4fd57cb7099c.herokuapp.com/",
    githubLink: "https://github.com/ibarrasb/getaway-genius",
  },
  {
    title: "Sideline Shop",
    image: projectImage2,
    description: `E-commerece application for sports related items and memorabilia, which features customer and admin logins`,
    techstack: "JavaScript, Node.js, Express, React.js, MongoDB, Mongoose, Cloudinary, JWT, Heroku",
    previewLink: "https://sideline-shop-0fa1380ddc56.herokuapp.com/",
    githubLink: "https://github.com/ibarrasb/sidelineshop",
  },
  
];

// Enter your Contact Details here
export const contactDetails = {
  email: "eddieibarra43@gmail.com",
  phone: "214-587-2001",
};
